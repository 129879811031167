import * as React from 'react';
import { IDropDownMenuProps } from '../../../DropDownMenu.types';
import MenuButton from '../../../../MenuButton/viewer/skinComps/PointerMenuButtonNSkin/PointerMenuButtonNSkin';
import PointerMenuButton from './PointerMenuButton';
import styles from './PointerMenuButtonSkin.scss';

const PointerMenuButtonSkin: React.FC<IDropDownMenuProps> = props => {
  return <PointerMenuButton {...props} styles={styles} Button={MenuButton} />;
};

export default PointerMenuButtonSkin;
